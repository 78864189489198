<template lang="html">
  <ValidationObserver v-slot="{ handleSubmit }" ref="observer" slim>
    <form @submit.prevent="handleSubmit(handleSubmission)" class="Form">
      <div class="Form__wrapper">
        <h2 class="title-6 mb-16">Event details</h2>
        <formly-form
          :form="form"
          :model="model"
          :fields="fields"
          tag="div"
          class="Form__group"
        ></formly-form>
      </div>
      <button class="btn btn--primary">
        {{ action ? action : 'Publish Event' }}
      </button>
    </form>
  </ValidationObserver>
</template>

<script>
  import { mapState } from 'vuex';
  import Time from '@/helpers/TimeHelper';
  import config from '@/config/events_config.js';

  export default {
    name: 'EventForm',
    props: {
      action: {
        type: String || Boolean,
        required: false,
        default: false,
      },
    },
    data: function () {
      return {
        model: {
          status: config.event_status[0].value,
          name: null,
          description: null,
          address: null,
          date: null,
          time: null,
          division_id: null,
          courts: null,
          duration: null,
          game_length: null,
          break_between_games: null,
          minimum_games_per_player: null,
          registration_end: null,
        },
        form: {},
        fields: [
          {
            key: 'status',
            type: 'select',
            rules: 'required',
            templateOptions: {
              label: 'Registration status',
              options: config.event_status,
              layout: 'full',
            },
          },
          {
            key: 'name',
            type: 'input',
            rules: 'required',
            templateOptions: {
              type: 'text',
              label: 'Event Name',
              layout: 'full',
            },
          },
          {
            key: 'description',
            type: 'textarea',
            templateOptions: {
              type: 'text',
              label: 'Description',
              layout: 'full',
            },
          },
          {
            key: 'address',
            type: 'input',
            rules: 'required',
            templateOptions: {
              type: 'text',
              label: 'Address',
              layout: 'full',
            },
          },
          {
            key: 'date',
            type: 'datepicker',
            rules: 'required',
            templateOptions: {
              type: 'date',
              defaultPanel: 'month',
              format: 'DD MMM YYYY',
              valueType: 'YYYY-MM-DD',
              label: 'Event date',
              disableDatesMode: 'future',
              layout: 'half',
            },
          },
          {
            key: 'time',
            type: 'timeSelect',
            rules: 'required',
            templateOptions: {
              type: 'time',
              valueType: 'HH:mm',
              label: 'Event starts at time',
              layout: 'half',
            },
          },
          {
            key: 'registration_end',
            type: 'datepicker',
            rules: '',
            templateOptions: {
              label: 'Registration end (optional)',
              type: 'date',
              defaultPanel: 'month',
              format: 'DD MMM YYYY',
              valueType: 'YYYY-MM-DD',
              disableDatesMode: false,
              layout: 'full',
            },
          },
          {
            key: 'division_id',
            type: 'divisionSelect',
            rules: '',
            templateOptions: {
              label: 'Age group / Division',
              layout: 'full',
            },
          },
          {
            key: 'courts',
            type: 'input',
            rules: 'required|min:1',
            templateOptions: {
              type: 'number',
              label: 'Number of courts available',
              layout: 'half',
            },
          },
          {
            key: 'duration',
            type: 'select',
            rules: 'min_value:30|numeric',
            templateOptions: {
              type: 'number',
              label: 'Event duration',
              options: config.event_duration,
              layout: 'half',
            },
          },
          {
            key: 'minimum_games_per_player',
            type: 'input',
            rules: 'required|min_value:1|numeric',
            templateOptions: {
              type: 'number',
              label: 'Minimum amount of games each player should play',
              layout: 'full',
            },
          },
          {
            key: 'game_length',
            type: 'select',
            rules: 'min_value:3|numeric',
            templateOptions: {
              type: 'number',
              label: 'Game duration (Optional)',
              options: config.game_lengths,
              layout: 'half',
            },
          },
          {
            key: 'break_between_games',
            type: 'select',
            rules: 'min_value:3|numeric',
            templateOptions: {
              type: 'number',
              label: 'Break between games (Optional)',
              options: config.game_break,
              layout: 'half',
            },
          },
        ],
      };
    },
    beforeMount() {
      if (this.$route.params.id) {
        if (this.$store.state.events.current.id != this.$route.params.id) {
          this.$store.dispatch('events/GET_EVENT', this.$route.params.id);
        } else {
          this.setInitialData();
        }
      }
    },
    methods: {
      handleSubmitEvent() {
        this.$refs.observer.handleSubmit(this.handleSubmission);
      },
      handleSubmission() {
        let mdl = this.model;
        let data = {
          status: mdl.status,
          name: mdl.name,
          description: mdl.description,
          address: mdl.address,
          date: Time.formatDate(
            Time.convertLocalToUTC(`${mdl.date}T${mdl.time}`),
          ),
          division_id: mdl.division_id,
          courts: mdl.courts,
          duration: mdl.duration || 60,
          game_length: mdl.game_length || 15,
          minimum_games_per_player: mdl.minimum_games_per_player || 1,
          break_between_games: mdl.break_between_games || 3,
          registration_end: mdl.registration_end,
        };

        if (this.$route.params.id) {
          this.$store.dispatch('events/PUT_EVENT', {
            id: this.current.id,
            ...data,
          });
        } else {
          this.$store.dispatch('events/POST_EVENT', {
            ...data,
          });
        }
      },
      setInitialData() {
        this.model = { ...this.current };
        let time = Time.formatDate(Time.convertUtcToLocal(this.current.date));
        this.model.date = time.split(' ')[0];
        this.model.time = time.split(' ')[1].slice(0, 5);
        this.model.courts = this.current.courts.length;
      },
    },
    watch: {
      current() {
        this.setInitialData();
      },
    },
    computed: {
      ...mapState({
        current: (state) => state.events.current,
      }),
    },
  };
</script>
