<template>
  <div class="Dashboard">
    <Breadcrumbs />
    <dashboard-top>
      <screen-title :title="this.$store.state.user.team.name"></screen-title>
    </dashboard-top>
    <div class="container ml-0">
      <section-title title="Update event">
        <div
          class="btn btn--green"
          style="margin-right: 12px"
          @click="handleRestoreEvent"
          v-show="
            userIs('admin') && this.$store.state.events.current.is_archived
          "
        >
          Restore event
        </div>
        <div
          class="btn btn--error"
          @click="handleDelete"
          v-show="userIs('admin')"
        >
          <div v-if="this.$store.state.events.current.is_archived">
            Delete event
          </div>
          <div v-else>Archive event</div>
        </div>
      </section-title>
    </div>
    <div class="container ml-0">
      <Form ref="form" action="Update event" />
    </div>
  </div>
</template>

<script>
  import Form from '@/components/Forms/EventForm';
  export default {
    name: 'CreateEvent',
    components: {
      Form,
    },
    methods: {
      handleSubmit() {
        this.$refs.form.handleSubmitEvent();
      },

      handleRestoreEvent() {
        let confirmation = confirm(
          'Are you sure you want to restore this event?',
        );
        if (confirmation) {
          let event = { ...this.$store.state.events.current };
          this.$store.dispatch('events/PUT_EVENT', {
            id: event.id,
            is_archived: 0,
            status: event.status,
            name: event.name,
            address: event.address,
            courts: event.courts.length,
            date: event.date,
          });
        }
      },

      handleDelete() {
        let confirmation;
        if (this.$store.state.events.current.is_archived) {
          confirmation = confirm(
            'Are you sure you want to permanently remove this event?',
          );
        } else {
          confirmation = confirm(
            'Are you sure you want to archive this event?',
          );
        }
        if (confirmation)
          this.$store.dispatch('events/DELETE_EVENT', {
            ...this.$store.state.events.current,
          });
      },
    },
  };
</script>
